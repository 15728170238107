/* eslint-disable import/no-cycle */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { getToken, onMessage } from 'firebase/messaging';
import { useMemo } from 'react';
import { sendDeviceTokenService } from 'services/fcm';
import notification from 'components/Notification';
import Icon from 'components/Icon';
// import { useHistory } from 'react-router-dom';
import { readNotificationService } from 'services/notification';
import { messaging } from '../config/firebase';
import { CH_INSTALLATION, DEFAULT_CURRENT_PAGE, NOTICE_TYPE, PAGINATION_LIMIT } from '../constants';
import useBadge from './useBadge';
import useBuilding from './useBuilding';
import useTheme from './useTheme';

export const NOTICES = (notifyData) => {
  const { unitGroupId, destId, unitId, url, extraData = {} } = notifyData;
  const extraDataObj = typeof extraData === 'string' ? JSON.parse(extraData) : { ...extraData };
  const { code, status } = extraDataObj;
  const queryObj = {
    limit: PAGINATION_LIMIT,
    page: DEFAULT_CURRENT_PAGE,
  };
  if (status) queryObj.status = status;
  if (code) queryObj.code = code;
  const queryData = `?${new URLSearchParams(queryObj).toString()}`;

  return {
    MSG_NOTICE: `/buildings/${unitGroupId}/resident-communicate`,
    HELPDESK_NOTICE: `/buildings/${unitGroupId}/help-desk`,
    BOOKING_NOTICE: `/buildings/${unitGroupId}/amenities-orders${destId ? `/${destId}` : ''}`,
    VISITOR_NOTICE: `/buildings/${unitGroupId}/access-management${queryData}`,
    ELEVATOR_RESERVATION_NOTICE: `/buildings/${unitGroupId}/inventory-transfer-management${queryData}`,
    CONSTRUCTION_REGISTRATION_NOTICE: `/buildings/${unitGroupId}/construction-management${queryData}`,
    PAYMENT_NOTICE: `/buildings/${unitGroupId}/statistics/receipt${destId ? `?receiptId=${destId}` : ''}`,
    WALLET_NOTICE: `/buildings/${unitGroupId}/statistics/apartment-account${unitId ? `?unitId=${unitId}` : ''}`,
    REQUEST_NOTICE: `/buildings/${unitGroupId}/task${destId ? `/${destId}` : ''}`,
    SYNC_FEE_NOTICE_TO_FAST: `/buildings/${unitGroupId}/integrate-accounting`,
    SYNC_RECEIPT_TO_FAST: `/buildings/${unitGroupId}/integrate-accounting`,
    AMENITY_BOOKING_NOTICE: `/buildings/${unitGroupId}/amenities-orders${destId ? `/${destId}` : ''}`,
    UTILITY_BOOKING_NOTICE: `/buildings/${unitGroupId}/amenities-orders${destId ? `/${destId}` : ''}`,
    RECEIVE_PACKAGE_NOTICE: `/buildings/${unitGroupId}/receive-package-management`,
    WEBVIEW_NOTICE: url || '',
    CARD_NOTICE: `/buildings/${unitGroupId}/card-warehouse`,
    MAINTENANCE_SCHEDULE_NOTICE: `/buildings/${unitGroupId}/maintenance-schedule${
      extraDataObj?.date ? `?startTime=${extraDataObj?.date}` : ''
    }`,
    MODULE_NOTICE: '',
    CARD_REGISTRATION_NOTICE: `/buildings/${unitGroupId}/vehicle-card-registration${queryData}`,
  };
};

export default function useFcm() {
  const {
    getHelpDeskBadges,
    getChatBadges,
    getAccessManagementBadges,
    getInventoryTransferBadges,
    getConstructionBadges,
    getNotificationBadges,
    getAmenitiesBookingBadges,
    getRequestBadges,
    getVehicleCardRegistrationBadges,
  } = useBadge();

  const idBuilding = useMemo(() => {
    const pathLocation = window.location.pathname;
    const pathArray = pathLocation.split('/');
    const pathBuildingIndex = pathArray.findIndex((pathItem) => pathItem === 'buildings');
    if (pathBuildingIndex !== -1) {
      return pathArray[pathBuildingIndex + 1];
    }
    return null;
  }, [window.location.pathname]);

  const { getBuildings } = useBuilding();
  // const history = useHistory();
  const [theme] = useTheme();
  const sendDeviceTokenToServer = async (currentToken) => {
    localStorage.setItem(CH_INSTALLATION, currentToken);
    await sendDeviceTokenService(currentToken, 'browser');
  };

  const handleCallback = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      onMessage(messaging, async ({ data, notification: notificationData }) => {
        const title = notificationData?.title || data?.title;
        const body = notificationData?.body || data?.body;
        const { type, unitGroupId, notificationId } = data;
        const notificationTitle = title;
        if (idBuilding === unitGroupId) {
          switch (type) {
            case NOTICE_TYPE.MSG:
              await getChatBadges(unitGroupId);
              break;
            case NOTICE_TYPE.HELP_DESK:
              await getHelpDeskBadges(unitGroupId);
              break;
            case NOTICE_TYPE.REQUEST:
              await getRequestBadges();
              break;
            case NOTICE_TYPE.UTILITY_BOOKING:
              await getAmenitiesBookingBadges(unitGroupId);
              break;
            case NOTICE_TYPE.VISITOR:
              await getAccessManagementBadges(unitGroupId);
              break;
            case NOTICE_TYPE.ELEVATOR_RESERVATION:
              await getInventoryTransferBadges(unitGroupId);
              break;
            case NOTICE_TYPE.CONSTRUCTION_REGISTRATION:
              await getConstructionBadges(unitGroupId);
              break;
            case NOTICE_TYPE.CARD_REGISTRATION:
              await getVehicleCardRegistrationBadges(unitGroupId);
              break;
            case NOTICE_TYPE.PAYMENT:
              break;
            default:
              break;
          }
          await getNotificationBadges(unitGroupId);
        }
        await getBuildings();
        notification.open({
          message: notificationTitle,
          description: body,
          onClick: async (event) => {
            event.preventDefault();
            if (unitGroupId) {
              const path = NOTICES(data)?.[type] ?? '';
              if (type !== 'MSG_NOTICE') {
                await readNotificationService(unitGroupId, notificationId);
              }
              if (path) {
                if (type !== 'WEBVIEW_NOTICE') {
                  // history.replace(`/buildings/${unitGroupId}/reload`);
                  // setTimeout(() => {
                  //   if (idBuilding === unitGroupId) {
                  //     history.replace(path);
                  //   } else {
                  //     window.location = path;
                  //   }
                  // });
                  window.open(path);
                }
                if (type === 'WEBVIEW_NOTICE') {
                  window.open(path);
                }
              }
            }
          },
          icon: (
            <Icon
              css={{
                color: theme.primaryColor,
              }}
              name="icon-notification-outlined"
            />
          ),
          style: { cursor: 'pointer' },
        });
      });
    }
  };

  const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, { vapidKey: process.env.FCM_KEY_PAIR });
      await sendDeviceTokenToServer(token);
    }
  };

  return { requestPermission, handleCallback };
}
