import { API_TYPE_CALL_API, API_TYPE_DOWNLOAD_FILE, METHOD, X_UNIT_GROUP_ID, SERVER } from 'shared/constants';
import api from './api';

const ELEVATOR_RESERVATION_API_GETS = `${SERVER}/form-data/v1/elevator-reservation`;
const ELEVATOR_RESERVATION_API_EXPORT = `${SERVER}/form-data/v1/elevator-reservation/export`;
const ELEVATOR_RESERVATION_API_ADD = `${SERVER}/form-data/v1/elevator-reservation`;
const ELEVATOR_RESERVATION_API_REMOVE = (id) => `${SERVER}/form-data/v1/elevator-reservation/${id}`;
const ELEVATOR_RESERVATION_API_APPROVE = (id) => `${SERVER}/form-data/v1/elevator-reservation/${id}/approve`;
const ELEVATOR_RESERVATION_API_CANCEL = (id) => `${SERVER}/form-data/v1/elevator-reservation/${id}/cancel`;
const ELEVATOR_RESERVATION_API_IN_PROGRESS = (id) => `${SERVER}/form-data/v1/elevator-reservation/${id}/in-progress`;
const ELEVATOR_RESERVATION_API_COMPLETE = (id) => `${SERVER}/form-data/v1/elevator-reservation/${id}/complete`;
const ELEVATOR_RESERVATION_COUNT_BADGES = `${SERVER}/form-data/v1/elevator-reservation/count-badges`;

export const getInventoryTransfersService = async ({ filter, limit, page, idBuilding }) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ELEVATOR_RESERVATION_API_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          page,
          limit,
        },
        headers,
      },
    },
  });
  return response;
};

export const exportInventoryTransfersService = async (filterObj, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: ELEVATOR_RESERVATION_API_EXPORT,
      method: METHOD.GET,
      options: { params: { ...filterObj }, headers },
    },
  });
  return response;
};

export const addInventoryTransferService = async (body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ELEVATOR_RESERVATION_API_ADD,
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const removeInventoryTransferService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ELEVATOR_RESERVATION_API_REMOVE(id),
      method: METHOD.DELETE,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const approveInventoryTransferService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ELEVATOR_RESERVATION_API_APPROVE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const rejectInventoryTransferService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ELEVATOR_RESERVATION_API_CANCEL(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const inProgressInventoryTransferService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ELEVATOR_RESERVATION_API_IN_PROGRESS(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const completeInventoryTransferService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ELEVATOR_RESERVATION_API_COMPLETE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const countBadgesInventoryTransferService = async (idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: ELEVATOR_RESERVATION_COUNT_BADGES,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};
