/* eslint-disable no-param-reassign */
import moment from 'moment';
import queryString from 'query-string';
import { isDate, isArray, isObject, isString } from 'lodash';
import { Cookie, handleError, handleResponse } from 'shared/utils';
import {
  METHOD,
  API_TYPE_CALL_API,
  API_TYPE_CALL_API_PARSER,
  API_TYPE_CALL_API_WITH_FILE,
  API_TYPE_DOWNLOAD_FILE,
  COOKIE_TAG,
  whiteLabelConfig,
} from 'shared/constants';
import oidc from 'shared/config/oidc';
import getAccessToken from './accessToken';

const { AUTH_METHOD } = process.env;
const cache = Cookie.get(COOKIE_TAG);

const optionsDefault = {
  params: undefined,
  headers: {},
};

const defaultHeaders = {
  'content-type': 'application/json',
  Accept: 'application/json,text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8\n',
  'x-white-label-key': cache?.whiteLabel ?? whiteLabelConfig?.key ?? 'CYHOME',
};
if (AUTH_METHOD === 'SSO') {
  defaultHeaders['user-type'] = 'EMPLOYEE';
}

const trimData = (value, result) => {
  if (value === undefined || value === null) {
    return value;
  }
  if (moment.isMoment(value)) {
    return value;
  }
  if (isDate(value)) {
    return value;
  }
  if (isArray(value)) {
    value.forEach((item, index) => {
      if (!result[index]) {
        result[index] = {};
      }
      result[index] = trimData(item, result[index]);
    });
    result = Object.keys(result).map((key) => result[key]);
  } else if (isObject(value)) {
    Object.keys(value).forEach((key) => {
      if (!result[key]) {
        result[key] = {};
      }
      result[key] = trimData(value[key], result[key]);
    });
  } else if (isString(value)) {
    const regex = new RegExp(/( {2,})/g);
    result = value.trim().replace(regex, ' ');
  } else {
    result = value;
  }
  return result;
};

export async function callApi({ apiUrl, body, method, options = optionsDefault, accessToken }) {
  const option = {
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      ...defaultHeaders,
      ...options.headers,
      Authorization: accessToken,
    },
    method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer,
    body: undefined,
  };
  if (method !== METHOD.GET) {
    option.body = JSON.stringify(trimData(body, {}));
  }
  let url = apiUrl;
  const query = queryString.stringify(trimData(options.params, {}), { arrayFormat: 'bracket' });
  if (query) {
    url = [url, query].join('?');
  }
  try {
    const response = await fetch(url, option);
    try {
      let responseParser;
      if (options.bodyType === 'html') {
        responseParser = await response.text();
      } else {
        responseParser = await response.json();
      }
      if (!response.ok) {
        if (response.status < 500) {
          const result = typeof responseParser === 'string' ? JSON.parse(responseParser) : responseParser;
          return {
            headers: response.headers,
            error: {
              ...result,
              statusCode: response.status,
            },
          };
        }
        return {
          headers: response.headers,
          error: {
            ...responseParser,
            statusCode: response.status,
            code: 'SERVER_ERROR',
          },
        };
      }
      return options.bodyType === 'html'
        ? {
            headers: response.headers,
            code: response.status,
            data: responseParser,
          }
        : {
            headers: response.headers,
            ...responseParser,
            code: response.status,
          };
    } catch (err) {
      if (response.status === 204) {
        return {
          code: 200,
        };
      }
      return {
        error: {
          ...body,
          code: 'FAIL',
          statusCode: 500,
          message: err,
        },
      };
    }
  } catch (err) {
    return {
      error: {
        ...body,
        code: 'FAIL',
        statusCode: 500,
        message: err,
      },
    };
  }
}

export async function downloadFile({ apiUrl, body, method, options = optionsDefault, accessToken }) {
  const option = {
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      ...defaultHeaders,
      ...options.headers,
      Authorization: accessToken,
    },
    method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer,
    body: undefined,
  };
  if (method !== METHOD.GET && method !== METHOD.DELETE) {
    option.body = JSON.stringify(trimData(body, {}));
  }
  let url = apiUrl;
  const query = queryString.stringify(trimData(options.params, {}), { arrayFormat: 'bracket' });
  if (query) {
    url = [url, query].join('?');
  }
  // create request
  try {
    const response = await fetch(url, option);
    if (response.ok) {
      if (response.status !== 200) {
        try {
          let result = await response.json();
          if (response.status < 500) {
            result = typeof result === 'string' ? JSON.parse(result) : result;
            return {
              headers: response.headers,
              error: {
                ...result,
                statusCode: response.status,
              },
            };
          }
          return {
            headers: response.headers,
            error: {
              ...result,
              statusCode: response.status,
              code: 'SERVER_ERROR',
            },
          };
        } catch (e) {
          return {
            headers: response.headers,
            error: {
              message: e,
              statusCode: 500,
              code: 'SERVER_ERROR',
            },
          };
        }
      } else {
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(new Blob([blob]));
        return {
          headers: response.headers,
          code: response.status,
          data: objectUrl,
          error: undefined,
        };
      }
    } else {
      return {
        headers: response.headers,
        error: {
          message: 'Can not connect to server',
          statusCode: 500,
          code: 'FAIL',
        },
      };
    }
  } catch (error) {
    return {
      error: {
        message: error,
        statusCode: 500,
        code: 'FAIL',
      },
    };
  }
}

export async function callApiWithFile({ apiUrl, file, method, name = 'file', options = optionsDefault, accessToken }) {
  const headers = {};
  if (AUTH_METHOD === 'SSO') {
    headers['user-type'] = 'EMPLOYEE';
  }
  const option = {
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, same-origin, *omit
    headers: {
      ...headers,
      ...options.headers,
      Authorization: accessToken,
    },
    method, // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // *client, no-referrer,
    body: undefined,
  };
  const formData = new FormData();
  formData.append(name, file);
  if (options.dataAttach) {
    Object.keys(options.dataAttach).forEach((key) => {
      if (options.dataAttach[key]) {
        formData.append(key, options.dataAttach[key]);
      }
    });
  }
  if (method !== METHOD.GET && method !== METHOD.DELETE) {
    option.body = formData;
  }
  let url = apiUrl;
  const query = queryString.stringify(options.params, { arrayFormat: 'bracket' });
  if (query) {
    url = [url, query].join('?');
  }
  try {
    const response = await fetch(url, option);

    try {
      let result = await response.json();
      if (!response.ok) {
        if (response.status < 500) {
          result = typeof result === 'string' ? JSON.parse(result) : result;
          return {
            headers: response.headers,
            error: {
              ...result,
              statusCode: response.status,
            },
          };
        }
        return {
          headers: response.headers,
          error: {
            ...result,
            statusCode: response.status,
            code: 'FAIL',
          },
        };
      }
      return {
        headers: response.headers,
        ...result,
        code: response.status,
      };
    } catch (err) {
      if (response.status === 204) {
        return {
          code: 200,
        };
      }
      return {
        error: {
          message: err,
          statusCode: 500,
          code: 'FAIL',
        },
      };
    }
  } catch (err) {
    return {
      error: {
        message: err,
        statusCode: 500,
        code: 'FAIL',
      },
    };
  }
}

export async function callApiParse({ apiUrl, body, method, options, accessToken }) {
  const newOptions = {
    headers: {
      'X-Parse-Application-Id': process.env.PARSE_SERVER_APPLICATION_ID,
      'X-Parse-Rest-Api-Key': process.env.PARSE_SERVER_REST_API_KEY,
      ...options.headers,
    },
    params: {
      ...trimData(options.params, {}),
    },
  };
  return callApi({ apiUrl, body: trimData(body, {}), method, options: newOptions, accessToken });
}

export default async ({ type, payload }) => {
  try {
    const accessToken = await getAccessToken();
    if (
      payload.apiUrl.indexOf('/login') === -1 &&
      payload.apiUrl.indexOf('/register') === -1 &&
      payload.apiUrl.indexOf('/forgot-password') === -1 &&
      payload.apiUrl.indexOf('/set-new-password') === -1 &&
      payload.apiUrl.indexOf('/check-maintenance') === -1
    ) {
      if (!accessToken) {
        const err = {
          code: 401,
          message: 'Error',
          codeLanguage: 'Error',
        };
        throw err;
      }
    }
    const newPayload = {
      ...payload,
      accessToken,
    };
    switch (type) {
      case API_TYPE_CALL_API: {
        const response = await callApi(newPayload);
        return handleResponse({ response, ...newPayload });
      }
      case API_TYPE_DOWNLOAD_FILE: {
        const response = await downloadFile(newPayload);
        return handleResponse({ response, ...newPayload });
      }
      case API_TYPE_CALL_API_WITH_FILE: {
        const response = await callApiWithFile(newPayload);
        return handleResponse({ response, ...newPayload });
      }
      case API_TYPE_CALL_API_PARSER: {
        const response = await callApiParse(newPayload);
        return handleResponse({ response, ...newPayload });
      }
      default: {
        const response = await callApi(newPayload);
        return handleResponse({ response, ...newPayload });
      }
    }
  } catch (err) {
    if (err.code === 401 && !['/login', '/password/forgot', '/register', '/password/set-new-password'].includes(window.location.pathname)) {
      if (AUTH_METHOD === 'SSO') oidc.signinRedirect();
      else {
        const cookieCache = Cookie.get(COOKIE_TAG);
        Cookie.save(COOKIE_TAG, {
          ...cookieCache,
          user: undefined,
          directUrl: `${window.location.pathname}${window.location.search}`,
        });
        window.location = '/login';
      }
    }
    throw handleError(err);
  }
};
