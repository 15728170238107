import { API_TYPE_CALL_API, METHOD, POSITION_LEVEL, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const BLOCK = `${SERVER}/${POSITION_LEVEL}/block`;
const BLOCK_GETS = BLOCK;
const BLOCK_ADD = BLOCK;
const BLOCK_UPDATE = (idBlock) => `${BLOCK}/${idBlock}`;
const BLOCK_DELETE = (idBlock) => `${BLOCK}/${idBlock}`;

export const getBlocksService = async ({ idBuilding, filter }) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const params = {
    ...filter,
    unitGroupId: idBuilding,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BLOCK_GETS,
      method: METHOD.GET,
      options: {
        params,
        headers,
      },
    },
  });
  return response;
};

export const addBlockService = async ({ idBuilding, block }) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BLOCK_ADD,
      method: METHOD.POST,
      body: block,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const updateBlockService = async ({ idBuilding, idBlock, block }) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BLOCK_UPDATE(idBlock),
      method: METHOD.PUT,
      body: block,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const deleteBlockService = async ({ idBuilding, idBlock }) => {
  const headers = {
    [X_UNIT_GROUP_ID]: idBuilding,
  };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: BLOCK_DELETE(idBlock),
      method: METHOD.DELETE,
      options: {
        headers,
      },
    },
  });
  return response;
};
