import { API_TYPE_CALL_API, METHOD, X_UNIT_GROUP_ID, SERVER } from 'shared/constants';
import api from './api';

const VEHICLE_CARD_REGISTRATION_API_REGISTRATION = `${SERVER}/form-data/v1/card-registration`;
const VEHICLE_CARD_REGISTRATION_API_GETS = VEHICLE_CARD_REGISTRATION_API_REGISTRATION;
const VEHICLE_CARD_REGISTRATION_API_ADD = `${VEHICLE_CARD_REGISTRATION_API_REGISTRATION}/vehicle`;
const RESIDENT_CARD_REGISTRATION_API_ADD = `${VEHICLE_CARD_REGISTRATION_API_REGISTRATION}/resident`;
const VEHICLE_CARD_REGISTRATION_API_UPDATE = (id) => `${VEHICLE_CARD_REGISTRATION_API_REGISTRATION}/${id} `;
const VEHICLE_CARD_REGISTRATION_API_DETAIL = (id) => `${VEHICLE_CARD_REGISTRATION_API_REGISTRATION}/${id} `;
const VEHICLE_CARD_REGISTRATION_API_CANCEL = (id) => `${VEHICLE_CARD_REGISTRATION_API_REGISTRATION}/${id}/cancel`;
const VEHICLE_CARD_REGISTRATION_API_APPROVE = (id) => `${VEHICLE_CARD_REGISTRATION_API_REGISTRATION}/${id}/approve`;
const VEHICLE_CARD_REGISTRATION_API_DEPOSIT = (id) => `${VEHICLE_CARD_REGISTRATION_API_REGISTRATION}/${id}/deposit`;
const VEHICLE_CARD_REGISTRATION_API_REFUND = (id) => `${VEHICLE_CARD_REGISTRATION_API_REGISTRATION}/${id}/refund`;
const VEHICLE_CARD_REGISTRATION_API_IN_PROGRESS = (id) => `${VEHICLE_CARD_REGISTRATION_API_REGISTRATION}/${id}/in-progress`;
const VEHICLE_CARD_REGISTRATION_API_CONFIRMED = (id) => `${VEHICLE_CARD_REGISTRATION_API_REGISTRATION}/${id}/deposit/confirm`;
const VEHICLE_CARD_REGISTRATION_API_COMPLETE = (id) => `${VEHICLE_CARD_REGISTRATION_API_REGISTRATION}/${id}/complete`;
const VEHICLE_CARD_REGISTRATION_COUNT_BADGES = `${VEHICLE_CARD_REGISTRATION_API_REGISTRATION}/count-badges`;

export const getVehicleCardRegistrationService = async ({ filter, limit, page, idBuilding }) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VEHICLE_CARD_REGISTRATION_API_GETS,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          page,
          limit,
        },
        headers,
      },
    },
  });
  return response;
};

export const addVehicleCardRegistrationService = async (body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VEHICLE_CARD_REGISTRATION_API_ADD,
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};
export const addResidentCardRegistrationService = async (body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RESIDENT_CARD_REGISTRATION_API_ADD,
      method: METHOD.POST,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const updateVehicleCardRegistrationService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VEHICLE_CARD_REGISTRATION_API_UPDATE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const getVehicleCardRegistrationDetailService = async (id, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VEHICLE_CARD_REGISTRATION_API_DETAIL(id),
      method: METHOD.GET,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const cancelVehicleCardRegistrationService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VEHICLE_CARD_REGISTRATION_API_CANCEL(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const approveVehicleCardRegistrationService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VEHICLE_CARD_REGISTRATION_API_APPROVE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const depositVehicleCardRegistrationService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VEHICLE_CARD_REGISTRATION_API_DEPOSIT(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const refundVehicleCardRegistrationService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VEHICLE_CARD_REGISTRATION_API_REFUND(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const inProgressVehicleCardRegistrationService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VEHICLE_CARD_REGISTRATION_API_IN_PROGRESS(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const confirmedVehicleCardRegistrationService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VEHICLE_CARD_REGISTRATION_API_CONFIRMED(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const completeVehicleCardRegistrationService = async (id, body, idBuilding) => {
  const headers = { [X_UNIT_GROUP_ID]: idBuilding };
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VEHICLE_CARD_REGISTRATION_API_COMPLETE(id),
      method: METHOD.PUT,
      body,
      options: {
        headers,
      },
    },
  });
  return response;
};

export const countBadgesVehicleCardRegistrationService = async (idBuilding) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: VEHICLE_CARD_REGISTRATION_COUNT_BADGES,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};
