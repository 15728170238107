import { API_TYPE_CALL_API, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const FLOOR = `${SERVER}/floor`;
const FLOOR_GETS = `${FLOOR}/list`;
const FLOOR_ADD = FLOOR;
const FLOOR_UPDATE = (idFloor) => `${FLOOR}/${idFloor}`;
const FLOOR_DELETE = (idFloor) => `${FLOOR}/${idFloor}`;

export async function getFloorsService({ idBuilding, filter, page, limit }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: FLOOR_GETS,
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
        params: {
          ...filter,
          page,
          limit,
        },
      },
    },
  });
  return response;
}

export const addFloorService = async ({ idBuilding, floor }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: FLOOR_ADD,
      method: METHOD.POST,
      body: floor,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const updateFloorService = async ({ idBuilding, idFloor, floor }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: FLOOR_UPDATE(idFloor),
      method: METHOD.PUT,
      body: floor,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const deleteFloorService = async ({ idBuilding, idFloor }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: FLOOR_DELETE(idFloor),
      method: METHOD.DELETE,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};
