import { initializeApp } from 'firebase/app';
import { initializeAnalytics } from 'firebase/analytics';
import { getMessaging, isSupported, deleteToken } from 'firebase/messaging';
import { CH_INSTALLATION } from '../constants/firebase';

const firebaseConfig = {
  messagingSenderId: process.env.FCM_SENDER_ID,
  apiKey: process.env.FCM_API_KEY,
  projectId: process.env.FCM_PROJECT_ID,
  appId: process.env.FCM_APP_ID,
  // measurementId: process.env.GA_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);

// eslint-disable-next-line import/no-mutable-exports
let messaging;

if (isSupported() && !messaging) {
  messaging = getMessaging(app);
}

const deleteDeviceToken = () => {
  const deviceToken = localStorage.getItem(CH_INSTALLATION);
  if (deviceToken && messaging) {
    deleteToken(messaging);
    localStorage.removeItem(CH_INSTALLATION);
  }
  return deviceToken;
};

initializeAnalytics(app, {
  debug_mode: process.env.ENV !== 'PRODUCTION',
});

export { messaging, deleteDeviceToken };
