import { API_TYPE_CALL_API, API_TYPE_CALL_API_WITH_FILE, API_TYPE_DOWNLOAD_FILE, METHOD, SERVER, X_UNIT_GROUP_ID } from 'shared/constants';
import api from './api';

const RESIDENT = `${SERVER}/resident`;
const RESIDENT_GET_LIST = `${RESIDENT}/list`;
const RESIDENT_GET = (idResident) => `${RESIDENT}/${idResident}`;
const RESIDENT_ADD = RESIDENT;
const RESIDENT_UPDATE = RESIDENT;
const RESIDENT_DELETE_LIST = `${RESIDENT}/delete-multi`;
const RESIDENT_USER_ACTIVE = (idResident) => `${RESIDENT}/${idResident}/active`;
const RESIDENT_USER_INACTIVE = (idResident) => `${RESIDENT}/${idResident}/inactive`;
const RESIDENT_IMPORT = `${RESIDENT}/import-data`;
const RESIDENT_EXPORT = `${RESIDENT}/export-data`;
const RESIDENT_CHANGED_USED_APP = (idResident) => `${RESIDENT}/${idResident}/used-app`;

export async function getResidentsService({ filter, page, limit, idBuilding }) {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RESIDENT_GET_LIST,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
          page,
          limit,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export const getResidentService = async ({ idResident, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RESIDENT_GET(idResident),
      method: METHOD.GET,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const addResidentService = async ({ idBuilding, resident }) => {
  const response = await await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RESIDENT_ADD,
      method: METHOD.POST,
      body: resident,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const updateResidentService = async ({ idResident, resident, idBuilding }) => {
  const response = await await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: `${RESIDENT_UPDATE}/${idResident}`,
      method: METHOD.PUT,
      body: resident,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const removeResidentService = async ({ idBuilding, idResidents, filter, isAll }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RESIDENT_DELETE_LIST,
      body: {
        array: idResidents,
        filterObject: filter,
        isAll,
      },
      method: METHOD.POST,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const activeAccountResidentService = async ({ idResident, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RESIDENT_USER_ACTIVE(idResident),
      method: METHOD.PUT,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export const inactiveAccountResidentService = async ({ idResident, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RESIDENT_USER_INACTIVE(idResident),
      method: METHOD.PUT,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};

export async function importResidentService({ idBuilding, file }) {
  const response = await api({
    type: API_TYPE_CALL_API_WITH_FILE,
    payload: {
      apiUrl: RESIDENT_IMPORT,
      file,
      method: METHOD.POST,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export async function exportResidentService({ idBuilding, filter }) {
  const response = await api({
    type: API_TYPE_DOWNLOAD_FILE,
    payload: {
      apiUrl: RESIDENT_EXPORT,
      method: METHOD.GET,
      options: {
        params: {
          ...filter,
        },
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
}

export const changedUsedAppResidentService = async ({ idResident, idBuilding }) => {
  const response = await api({
    type: API_TYPE_CALL_API,
    payload: {
      apiUrl: RESIDENT_CHANGED_USED_APP(idResident),
      method: METHOD.PATCH,
      options: {
        headers: {
          [X_UNIT_GROUP_ID]: idBuilding,
        },
      },
    },
  });
  return response;
};
