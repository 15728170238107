/** @jsx jsx */
import 'react-hot-loader';
import ReactDOM from 'react-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import moment from 'moment';
import { Cookie, getCacheLanguage } from 'shared/utils';
import socket from 'shared/config/socket';
import { COOKIE_TAG, whiteLabelConfig } from 'shared/constants';
import 'moment/locale/eu';
import 'moment/locale/vi';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import enGB from 'antd/es/locale/en_GB';
import viVN from 'antd/es/locale/vi_VN';
import jaJp from 'antd/es/locale/ja_JP';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from 'components/Error';
import { jsx, ThemeProvider } from '@emotion/react';
import GlobalContext from './globalContext';
import reducers, { initState } from './reducers';
import saga from './saga';
import Routes from './routes/root';

require('../public/assets/styles/index.scss');

const sagaMiddleware = createSagaMiddleware();
const cache = Cookie.get(COOKIE_TAG);

const defaultState = {
  ...initState,
  application: {
    ...initState.application,
  },
};

moment.locale(cache?.language ?? 'vi');
moment.locale(cache?.language, {
  week: {
    dow: 1, // Date offset
  },
});

let middleware = [sagaMiddleware, socket()];

if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, logger];
}

const enhancers = [applyMiddleware(...middleware)];

const composeEnhancers =
  process.env.NODE_ENV === 'development' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ shouldHotReload: false })
    : compose;

export const store = createStore(reducers, defaultState, composeEnhancers(...enhancers));

sagaMiddleware.run(saga);

const Root = (props) => {
  const { store: storeData } = props;

  const getLanguage = () => {
    if (getCacheLanguage() === 'en') return enGB;
    if (getCacheLanguage() === 'jp') return jaJp;
    viVN.DatePicker.lang.monthFormat = '[Tháng] MM';
    viVN.DatePicker.lang.shortMonths = [
      'Tháng 1',
      'Tháng 2',
      'Tháng 3',
      'Tháng 4',
      'Tháng 5',
      'Tháng 6',
      'Tháng 7',
      'Tháng 8',
      'Tháng 9',
      'Tháng 10',
      'Tháng 11',
      'Tháng 12',
    ];
    viVN.DatePicker.lang.shortWeekDays = ['CN', 'TH2', 'TH3', 'TH4', 'TH5', 'TH6', 'TH7'];
    return viVN;
  };

  return (
    <ThemeProvider theme={whiteLabelConfig.theme}>
      <Provider store={storeData}>
        <ConfigProvider locale={getLanguage()}>
          <ErrorBoundary>
            <GlobalContext>
              <Router>
                <div css={{ height: '100%', overflow: 'hidden' }}>
                  <Routes />
                </div>
              </Router>
            </GlobalContext>
          </ErrorBoundary>
        </ConfigProvider>
      </Provider>
    </ThemeProvider>
  );
};

Root.propTypes = {
  store: PropTypes.objectOf(PropTypes.any),
};

Root.defaultProps = {
  store: {},
};

ReactDOM.render(<Root store={store} />, document.getElementById('root'));
