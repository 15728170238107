import { useContext, useMemo } from 'react';
import { getLabelsService, getLabelTreeService } from 'services/label';
import { isNil } from 'shared/utils';
// eslint-disable-next-line import/no-cycle
import { Context as GlobalContext } from 'globalContext';
import { LABEL_ACCOUNTING, LABEL_ALL, LABEL_CUSTOMER_CODE } from '../constants/label';

const useLabel = () => {
  const { labels, setLabels, labelTrees, setLabelTrees } = useContext(GlobalContext);

  // function
  const getLabels = async (idBuilding) => {
    const response = await getLabelsService(idBuilding);
    setLabels(response.data);
  };

  const getLabelTree = async (idBuilding) => {
    const response = await getLabelTreeService(idBuilding);
    setLabelTrees(response.data);
  };

  const [labelData, labelTreeData, labelsCustomerCodeData, labelsAccountingData, labelTreeDataStatistics] = useMemo(
    () => [
      labels,
      labelTrees,
      labels.filter((label) => label.type === LABEL_CUSTOMER_CODE || label.type === LABEL_ALL),
      labels.filter((label) => label.type === LABEL_ACCOUNTING || label.type === LABEL_ALL),
      labelTrees.filter((labelTree) => {
        if (!isNil({ value: labelTree.color })) {
          if ([LABEL_ALL, LABEL_ACCOUNTING].includes(labelTree.type)) {
            return true;
          }
          return false;
        }
        return true;
      }),
    ],
    [labels, labelTrees]
  );
  return {
    getLabelTree,
    getLabels,
    labelData,
    labelTreeData,
    labelsCustomerCodeData,
    labelsAccountingData,
    labelTreeDataStatistics,
    setLabels,
    setLabelTrees,
  };
};

export default useLabel;
