export const GA_EVENT_FILTER = 'BoLoc';
export const GA_EVENT_FILTER_SEARCH = 'BoLoc/TimKiem';
export const GA_EVENT_FILTER_REFRESH = 'BoLoc/LamMoi';
export const GA_EVENT_FILTER_ADD = 'BoLoc/ThemBoLoc';
export const GA_EVENT_EXPORT = 'TaiXuong';
export const GA_EVENT_IMPORT = 'TaiLen';
export const GA_EVENT_ACTION = 'HanhDong';
export const GA_EVENT_ACTION_REFRESH = 'HanhDong/LamMoi';
export const GA_EVENT_ACTION_EXPORT = 'HanhDong/TaiXuong';
export const GA_EVENT_ACTION_DELETE = 'HanhDong/Xoa';
export const GA_EVENT_ACTION_ACTIVE = 'HanhDong/KichHoat';
export const GA_EVENT_ACTION_INACTIVE = 'HanhDong/NgungHoatDong';
export const GA_EVENT_ADD = 'Them';
export const GA_EVENT_ADD_ADJUSTMENT = 'ThemDieuChinh';
export const GA_EVENT_PUBLIC = 'PhatHanh';
export const GA_EVENT_UPDATE = 'CapNhat';
export const GA_EVENT_DELETE = 'Xoa';
export const GA_EVENT_REFRESH = 'LamMoi';
export const GA_EVENT_HISTORY = 'LichSu';
export const GA_EVENT_PRINT = 'In';
export const GA_EVENT_EMAIL = 'Email';
export const GA_EVENT_APPROVE = 'Duyet';
export const GA_EVENT_REJECT = 'TuChoi';
export const GA_EVENT_CHECK_IN = 'CheckIn';
export const GA_EVENT_CHECK_OUT = 'CheckOut';
export const GA_EVENT_DETAIL = 'ChiTiet';
export const GA_EVENT_STATISTICS = 'ThongKe';
export const GA_EVENT_DUPLICATE = 'TaoBanSao';

export const GA_LABEL_BUTTON = 'BUTTON';
export const GA_LABEL_DROPDOWN = 'DROPDOWN';
export const GA_LABEL_LINK = 'LINK';

export const GA_ACTIVITY_LOG = 'ActivityLog';
export const GA_LOGIN = 'DangNhap';
export const GA_REGISTER = 'DangNhap/DangKy';
export const GA_FORGET_PASSWORD = 'DangNhap/QuenMatKhau';
export const GA_DASHBOARD = 'Dashboard';
export const GA_APARTMENT = 'CanHo';
export const GA_APARTMENT_DETAIL = 'CanHo/ChiTiet';
export const GA_RESIDENT = 'CuDan';
export const GA_RESIDENT_DETAIL = 'CuDan/ChiTiet';
export const GA_SERVICE_SAVE_FROM_TO = 'DichVu/LuuDauCuoi';
export const GA_SERVICE_SAVE_NUMBER_USE = 'DichVu/SoSuDung';
export const GA_SERVICE_PARKING = 'DichVu/GuiXe';
export const GA_SERVICE_REGISTER = 'DichVu/DangKy';
export const GA_CARD_WAREHOUSE = 'The/Kho';
export const GA_CARD_REGISTER = 'The/PhieuDangKy';
export const GA_SERVICE_FEE = 'PhiDichVu';
export const GA_SERVICE_FEE_CREATE = 'PhiDichVu/TaoPhi';
export const GA_FEE_NOTIFICATION_SEND = 'GuiThongBaoPhi';
export const GA_PAYMENT_SINGLE_APARTMENT = 'ThanhToan/1CanHo';
export const GA_PAYMENT_MULTI_APARTMENT = 'ThanhToan/NhieuCanHo';
export const GA_AMENITIES_INDOOR = 'TienIchNoiKhu';
export const GA_AMENITIES_INDOOR_DETAIL = 'TienIchNoiKhu/ChiTietDon';
export const GA_AMENITIES_OUTDOOR = 'TienIchDoiTac';
export const GA_AMENITIES_OUTDOOR_DETAIL = 'TienIchDoiTac/ChiTietDon';
export const GA_CUSTOMER_SERVICE_ANNOUNCEMENT = 'CSKH/ThongBao';
export const GA_CUSTOMER_SERVICE_HELP_DESK = 'CSKH/PhanAnh';
export const GA_CUSTOMER_SERVICE_COMMUNICATE = 'CSKH/GiaoTiep';
export const GA_CUSTOMER_SERVICE_CHAT_ARCHIVE = 'CSKH/GiaoTiepLuuTru';
export const GA_ACCESS_MANAGEMENT = 'RaVao';
export const GA_TASK = 'CongViec';
export const GA_TASK_DETAIL = 'CongViec/ChiTiet';
export const GA_STAFF = 'NhanVien';
export const GA_DOCUMENT = 'TaiLieu';
export const GA_ASSET = 'TrangThietBiVatTu/TrangThietBi';
export const GA_SUPPLY = 'TrangThietBiVatTu/VatTu';
export const GA_PARTNER = 'TrangThietBiVatTu/NhaCungCap';
export const GA_CASHBOOK = 'ThongKe/SoQuy';
export const GA_RECEIPT = 'ThongKe/PhieuThu';
export const GA_PAYSLIP = 'ThongKe/PhieuChi';
export const GA_DEBT = 'ThongKe/CongNo';
export const GA_ADVANCE_PAYMENT = 'ThongKe/ThuTruoc';
export const GA_PAYMENT_ONLINE = 'ThongKe/ThanhToanTrucTuyen';
export const GA_APARTMENT_ACCOUNT = 'ThongKe/TaiKhoanCanHo';
export const GA_FINANCIAL = 'ThongKe/TaiChinh';
export const GA_CONFIG_BUILDING = 'CauHinh/ToaNha';
export const GA_CONFIG_SERVICE = 'CauHinh/DichVu';
export const GA_CONFIG_AMENITIES = 'CauHinh/TienIch';
export const GA_CONFIG_TARIFF = 'CauHinh/BieuPhi';
export const GA_CONFIG_FUND = 'CauHinh/Quy';
export const GA_CONFIG_LABEL = 'CauHinh/Nhan';
export const GA_CONFIG_TEMPLATE = 'CauHinh/BieuMau';
export const GA_AUTHORIZATION = 'CauHinh/QuyenHeThong';

export const GA_MAPPING = {
  '/buildings/([0-9]+)/activity': GA_ACTIVITY_LOG,
  '/buildings/([0-9]+)/dashboard': GA_DASHBOARD,
  '/buildings/([0-9]+)/apartments': GA_APARTMENT,
  '/buildings/([0-9]+)/apartments/[0-9]+': GA_APARTMENT_DETAIL,
  '/buildings/([0-9]+)/residents': GA_RESIDENT,
  '/buildings/([0-9]+)/residents/[0-9]+': GA_RESIDENT_DETAIL,
  '/buildings/([0-9]+)/services/save-from-to': GA_SERVICE_SAVE_FROM_TO,
  '/buildings/([0-9]+)/services/save-number-use': GA_SERVICE_SAVE_NUMBER_USE,
  '/buildings/([0-9]+)/services/parking': GA_SERVICE_PARKING,
  '/buildings/([0-9]+)/services/register': GA_SERVICE_REGISTER,
  '/buildings/([0-9]+)/card-warehouse': GA_CARD_WAREHOUSE,
  '/buildings/([0-9]+)/card-registration-form': GA_CARD_REGISTER,
  '/buildings/([0-9]+)/bill-list': GA_SERVICE_FEE,
  '/buildings/([0-9]+)/bill-add': GA_SERVICE_FEE_CREATE,
  '/buildings/([0-9]+)/fee-notifications-send': GA_FEE_NOTIFICATION_SEND,
  '/buildings/([0-9]+)/payment/single-apartment': GA_PAYMENT_SINGLE_APARTMENT,
  '/buildings/([0-9]+)/payment/multi-apartment': GA_PAYMENT_MULTI_APARTMENT,
  '/buildings/([0-9]+)/amenities-orders': GA_AMENITIES_INDOOR,
  '/buildings/([0-9]+)/amenities-orders/:idAmenitiesOrder': GA_AMENITIES_INDOOR_DETAIL,
  '/buildings/([0-9]+)/amenities-merchant-orders': GA_AMENITIES_OUTDOOR,
  '/buildings/([0-9]+)/amenities-merchant-orders/:idAmenitiesMerchantOrder': GA_AMENITIES_OUTDOOR_DETAIL,
  '/buildings/([0-9]+)/announcement': GA_CUSTOMER_SERVICE_ANNOUNCEMENT,
  '/buildings/([0-9]+)/help-desk': GA_CUSTOMER_SERVICE_HELP_DESK,
  '/buildings/([0-9]+)/resident-communicate': GA_CUSTOMER_SERVICE_COMMUNICATE,
  '/buildings/([0-9]+)/resident-message-archive': GA_CUSTOMER_SERVICE_CHAT_ARCHIVE,
  '/buildings/([0-9]+)/access-management': GA_ACCESS_MANAGEMENT,
  '/buildings/([0-9]+)/task': GA_TASK,
  '/buildings/([0-9]+)/task/:idRequest': GA_TASK_DETAIL,
  '/buildings/([0-9]+)/staffs': GA_STAFF,
  '/buildings/([0-9]+)/documents': GA_DOCUMENT,
  '/buildings/([0-9]+)/asset': GA_ASSET,
  '/buildings/([0-9]+)/supplies': GA_SUPPLY,
  '/buildings/([0-9]+)/partner': GA_PARTNER,
  '/buildings/([0-9]+)/statistics/cashbook': GA_CASHBOOK,
  '/buildings/([0-9]+)/statistics/receipt': GA_RECEIPT,
  '/buildings/([0-9]+)/statistics/payslip': GA_PAYSLIP,
  '/buildings/([0-9]+)/statistics/debt': GA_DEBT,
  '/buildings/([0-9]+)/statistics/advance-payment': GA_ADVANCE_PAYMENT,
  '/buildings/([0-9]+)/statistics/payment-online': GA_PAYMENT_ONLINE,
  '/buildings/([0-9]+)/statistics/apartment-account': GA_APARTMENT_ACCOUNT,
  '/buildings/([0-9]+)/statistics/financial': GA_FINANCIAL,
  '/buildings/([0-9]+)/configs-buildings': GA_CONFIG_BUILDING,
  '/buildings/([0-9]+)/configs-services': GA_CONFIG_SERVICE,
  '/buildings/([0-9]+)/configs-amenities': GA_CONFIG_AMENITIES,
  '/buildings/([0-9]+)/configs-tariffs': GA_CONFIG_TARIFF,
  '/buildings/([0-9]+)/configs-funds': GA_CONFIG_FUND,
  '/buildings/([0-9]+)/configs-labels': GA_CONFIG_LABEL,
  '/buildings/([0-9]+)/configs-templates': GA_CONFIG_TEMPLATE,
  '/buildings/([0-9]+)/configs-authorization': GA_AUTHORIZATION,
  '/login': GA_LOGIN,
  '/register': GA_REGISTER,
  '/password/forgot': GA_FORGET_PASSWORD,
};
