import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useQuery = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const removeQuery = (key) => {
    if (queryParams.has(key)) {
      queryParams.delete(key);
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  const removeAllQuery = () => {
    history.replace({
      pathname,
    });
  };

  const setQuery = (queryObj) => {
    if (!queryObj || !Object.keys(queryObj).length) return;
    Object.keys(queryObj).forEach((key) => {
      if (queryObj[key] || queryObj[key] === '') {
        queryParams.set(key, queryObj[key]);
      }
    });

    const queryData = queryParams.toString();
    const searchData = search.startsWith('?') ? search.replace('?', '') : search;

    if (searchData !== queryData && !searchData) {
      history.replace({
        search: queryData,
        pathname,
      });
    }

    if (searchData !== queryData && searchData) {
      history.push({
        search: queryData,
        pathname,
      });
    }
  };

  const getQueryByKey = (key) => {
    return queryParams.get(key);
  };

  const getAllQuery = () => {
    const result = {};
    queryParams.forEach((value, key) => {
      result[key] = value;
    });
    return result;
  };

  return { queryParams, removeQuery, setQuery, getQueryByKey, getAllQuery, removeAllQuery };
};

export default useQuery;
