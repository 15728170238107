import { call, put, all, takeLatest } from 'redux-saga/effects';
import { addResidentService, getResidentService, updateResidentService } from 'services/resident';
import { getApartmentsService } from 'services/apartment';
import { getCountryCodesService } from 'services/base';

import {
  RESIDENT_FORM_ADD,
  RESIDENT_FORM_GET_ENTITY,
  RESIDENT_FORM_GET_UNITS,
  RESIDENT_FORM_UPDATE,
  RESIDENT_FORM_GET_COUNTRY_CODE,
} from './residentForm.types';

export function* searchApartmentInFormResident(action) {
  const { idBuilding, filter, refreshState } = action.payload;
  try {
    const response = yield call(getApartmentsService, {
      idBuilding,
      page: 1,
      limit: 20,
      filter,
    });
    refreshState();
    yield put({
      type: RESIDENT_FORM_GET_UNITS.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState();
  }
}

export function* addResident(action) {
  const { idBuilding, resident, refreshState } = action.payload;
  try {
    const response = yield call(addResidentService, { idBuilding, resident });
    refreshState({
      code: response.code,
    });
    yield put({
      type: RESIDENT_FORM_ADD.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

export function* updateResident(action) {
  const { idBuilding, idResident, resident, refreshState } = action.payload;
  try {
    const response = yield call(updateResidentService, {
      idBuilding,
      idResident,
      resident,
    });
    refreshState({
      code: response.code,
    });
    yield put({
      type: RESIDENT_FORM_UPDATE.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState({
      code: error.code,
      codeLanguage: error.codeLanguage,
    });
  }
}

export function* getResident(action) {
  const { idBuilding, idResident, refreshState } = action.payload;
  try {
    const response = yield call(getResidentService, { idBuilding, idResident, refreshState });
    refreshState();
    yield put({
      type: RESIDENT_FORM_GET_ENTITY.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState();
  }
}

export function* getCountryCode(action) {
  const { refreshState } = action.payload;
  try {
    const response = yield call(getCountryCodesService);
    refreshState();
    yield put({
      type: RESIDENT_FORM_GET_COUNTRY_CODE.SUCCESS,
      payload: response,
    });
  } catch (error) {
    refreshState();
  }
}

export default function* watch() {
  yield all([
    takeLatest(RESIDENT_FORM_GET_UNITS.REQUEST, searchApartmentInFormResident),
    takeLatest(RESIDENT_FORM_ADD.REQUEST, addResident),
    takeLatest(RESIDENT_FORM_UPDATE.REQUEST, updateResident),
    takeLatest(RESIDENT_FORM_GET_ENTITY.REQUEST, getResident),
    takeLatest(RESIDENT_FORM_GET_COUNTRY_CODE.REQUEST, getCountryCode),
  ]);
}
