/* eslint-disable import/no-cycle */
import { useContext } from 'react';
import { Context } from 'globalContext';
import { getTotalRequestExpiredService } from 'services/request';
import { countHelpDeskBadgeService } from 'services/helpDesk';
import { countChatBadgesService } from 'services/chat';
import { countBadgesAmenitiesBookingService } from 'services/amenities';
import { countBadgesAccessManagementService } from 'services/accessManagement';
import { countBadgesInventoryTransferService } from 'services/inventoryTransfer';
import { countBadgesNotificationService } from 'services/notification';
import { countBadgesConstructionService } from 'services/construction';
import { countBadgesVehicleCardRegistrationService } from 'services/vehicleCardRegistration';

const useBadge = () => {
  const { badges, setBadges } = useContext(Context);

  const getBadges = async (idBuilding) => {
    const [
      requestBadge,
      helpDeskBadge,
      chatBadges,
      amenitiesBadges,
      accessManagementBadges,
      inventoryTransferBadges,
      constructionBadges,
      notificationBadges,
      cardRegistrationBadges,
    ] = await Promise.all([
      getTotalRequestExpiredService(),
      countHelpDeskBadgeService(idBuilding),
      countChatBadgesService(idBuilding),
      countBadgesAmenitiesBookingService(idBuilding),
      countBadgesAccessManagementService(idBuilding),
      countBadgesInventoryTransferService(idBuilding),
      countBadgesConstructionService(idBuilding),
      countBadgesNotificationService(idBuilding),
      countBadgesVehicleCardRegistrationService(idBuilding),
    ]);

    const requestBadgeOfBuilding = requestBadge.data.find((badge) => badge.unitGroupId === +idBuilding);

    setBadges({
      request: requestBadgeOfBuilding?.overDue ?? 0,
      helpDesk: helpDeskBadge?.data?.badge ?? 0,
      chat: chatBadges?.data?.badge ?? 0,
      amenities: amenitiesBadges?.data ?? 0,
      accessManagement: accessManagementBadges?.data?.badge ?? 0,
      inventoryTransfer: inventoryTransferBadges?.data?.badge ?? 0,
      construction: constructionBadges?.data?.badge ?? 0,
      notification: notificationBadges?.data?.badge ?? 0,
      cardRegistration: cardRegistrationBadges?.data?.badge ?? 0,
    });
  };

  const getHelpDeskBadges = async (idBuilding) => {
    if (badges.helpDesk === 0) {
      const result = await countHelpDeskBadgeService(idBuilding);
      setBadges({
        ...badges,
        helpDesk: result?.data?.badge ?? 0,
      });
    }
  };

  const getChatBadges = async (idBuilding) => {
    if (badges.chat === 0) {
      const chatBadges = await countChatBadgesService(idBuilding);
      setBadges({
        ...badges,
        chat: chatBadges?.data?.badge ?? 0,
      });
    }
  };

  const getAccessManagementBadges = async (idBuilding) => {
    if (badges.accessManagement === 0) {
      const accessManagementBadges = await countBadgesAccessManagementService(idBuilding);
      setBadges({
        ...badges,
        accessManagement: accessManagementBadges?.data?.badge ?? 0,
      });
    }
  };

  const getInventoryTransferBadges = async (idBuilding) => {
    if (badges.inventoryTransfer === 0) {
      const inventoryTransferBadges = await countBadgesInventoryTransferService(idBuilding);
      setBadges({
        ...badges,
        inventoryTransfer: inventoryTransferBadges?.data?.badge ?? 0,
      });
    }
  };

  const getConstructionBadges = async (idBuilding) => {
    if (badges.construction === 0) {
      const constructionBadges = await countBadgesConstructionService(idBuilding);
      setBadges({
        ...badges,
        construction: constructionBadges?.data?.badge ?? 0,
      });
    }
  };

  const getNotificationBadges = async (idBuilding) => {
    const notificationBadges = await countBadgesNotificationService(idBuilding);
    setBadges({
      ...badges,
      notification: notificationBadges?.data?.badge ?? 0,
    });
    return notificationBadges?.data?.badge ?? 0;
  };

  const getAmenitiesBookingBadges = async (idBuilding) => {
    const amenitiesBookingBadges = await countBadgesAmenitiesBookingService(idBuilding);
    setBadges({
      ...badges,
      amenities: amenitiesBookingBadges?.data ?? 0,
    });
  };

  const getRequestBadges = async (idBuilding) => {
    const requestBadge = await getTotalRequestExpiredService(idBuilding);
    const requestBadgeOfBuilding = requestBadge.data.find((badge) => badge.unitGroupId === +idBuilding);
    setBadges({
      ...badges,
      request: requestBadgeOfBuilding?.overDue ?? 0,
    });
  };

  const getVehicleCardRegistrationBadges = async (idBuilding) => {
    if (badges.cardRegistration === 0) {
      const cardRegistrationBadges = await countBadgesVehicleCardRegistrationService(idBuilding);
      setBadges({
        ...badges,
        cardRegistration: cardRegistrationBadges?.data?.badge ?? 0,
      });
    }
  };

  return {
    badges,
    setBadges,
    getBadges,
    getHelpDeskBadges,
    getChatBadges,
    getAccessManagementBadges,
    getInventoryTransferBadges,
    getConstructionBadges,
    getNotificationBadges,
    getAmenitiesBookingBadges,
    getRequestBadges,
    getVehicleCardRegistrationBadges,
  };
};

export default useBadge;
