import { language } from '../utils/language';

export const PC = 'PC';
export const UNC = 'UNC';
export const DO_NOT_USE_UNIT_ACCOUNT = 'DO_NOT_USE_UNIT_ACCOUNT';
export const REFUND_UNIT_ACCOUNT = 'REFUND_UNIT_ACCOUNT';
export const NOT_CANCELED = 'NOT_CANCELED';
export const CANCELED = 'CANCELED';
export const DELETE = 'DELETE';
export const PREVIEW_PRINT = 'PREVIEW_PRINT';
export const EDIT = 'EDIT';

export const PAYSLIP_STATUS_OPTIONS = [
  {
    id: NOT_CANCELED,
    name: language.label.not_canceled,
  },
  {
    id: CANCELED,
    name: language.label.canceled,
  },
];

export const TYPE_PAYSLIP = [
  {
    id: PC,
    name: language.label.cash_payment,
  },
  {
    id: UNC,
    name: language.label.standing_order,
  },
];

export const APARTMENT_ACCOUNT_PAYSLIP_TYPE_OPTIONS = [
  {
    id: DO_NOT_USE_UNIT_ACCOUNT,
    name: language.label.dont_use_apartment_account,
  },
  {
    id: REFUND_UNIT_ACCOUNT,
    name: language.label.refund_apartment_account,
  },
];

export const TOTAL_PAYMENT_EXCEEDING_ERROR = 'TOTAL_PAYMENT_EXCEEDING';
