import calculatePromotions from './calculatePromotions';
import calculateSurcharges from './calculateSurcharges';
import calculateSurchargeTax from './calculateSurchargeTax';
import calculateTax from './calculateTax';

export default function calculateDetailPayment({ subTotal: subTotalInp, totalNumberUse, vatSurcharge, vat, surcharges, promotions }) {
  const subTotal = Math.round(Number(subTotalInp));
  const totalDiscount = Math.round(calculatePromotions(promotions, subTotal, totalNumberUse));
  const totalPaymentAfterDiscount = Math.round(subTotal - totalDiscount);
  const totalSurcharge = Math.round(calculateSurcharges(surcharges, subTotal, totalNumberUse));
  const totalVatSurcharge = calculateSurchargeTax(vatSurcharge, totalSurcharge);
  const totalVat = calculateTax(vat, vatSurcharge, totalPaymentAfterDiscount, totalSurcharge);
  const totalPayment = totalPaymentAfterDiscount + totalSurcharge + totalVatSurcharge + totalVat;
  return {
    subTotal,
    totalDiscount,
    totalSurcharge,
    totalVatSurcharge,
    totalVat,
    totalPayment,
  };
}
