const templateDataForImport = {
  // Device
  name: {
    vi: ['Đồng hồ 1', 'Đồng hồ  2', 'Đồng hồ  3'],
    en: ['1st Clock', '2nd Clock', '3rd Clock'],
  },

  meterNumber: {
    vi: ['DH-0001', 'DH-0002', 'DH-0003'],
    en: ['DH-0001', 'DH-0002', 'DH-0003'],
  },

  contractTemplateName: {
    vi: ['Biểu phí 1', 'Biểu phí 2', 'Biểu phí 3'],
    en: ['contract template 1', 'contract template 2', 'contract template 3'],
  },

  status: {
    vi: ['Hoạt động', 'Ngừng hoạt động'],
    en: ['active', 'inactive'],
  },

  unitName: {
    vi: ['Căn hộ 101', 'Căn hộ 102', 'Căn hộ 103'],
    en: ['Gorgeous Apartment', 'Loving Apartment', 'Polite Apartment'],
  },
};

export default templateDataForImport;
