import { language } from '../utils/language';
import convertArrToObj from '../utils/convertArrToObj';

export const APARTMENT_BUILD_STATUS_OPTIONS = [
  {
    id: 'FINISHED_HANDED_OVER',
    name: language.finished_handover,
  },
  {
    id: 'UNFINISHED_HANDED_OVER',
    name: language.unfinished_handover,
  },
  {
    id: 'NOT_YET_HANDED_OVER',
    name: language.label.not_yet_handed_over,
  },
  {
    id: 'UNFURNISHED',
    name: language.label.unfurnished,
  },
  {
    id: 'HANDED_OVER',
    name: language.label.handed_over,
  },
  {
    id: 'CONSTRUCTION_IN_PROGRESS',
    name: language.label.construction_in_progress,
  },
  {
    id: 'CONSTRUCTION_OVER',
    name: language.label.construction_over,
  },
  {
    id: 'OWNED',
    name: language.label.owned,
  },
  {
    id: 'RENTED',
    name: language.label.rented,
  },
];

export const APARTMENT_BUILD_STATUS_BY_ID = convertArrToObj(APARTMENT_BUILD_STATUS_OPTIONS, 'id');

export const APARTMENT_TYPE_OPTIONS = [
  {
    id: 'VILLA',
    name: language.label.villa,
  },
  {
    id: 'APARTMENT',
    name: language.label.apartment2,
  },
  {
    id: 'SERVICE',
    name: language.label.service_apartment,
  },
  {
    id: 'OWNER',
    name: language.label.owner1,
  },
  {
    id: 'RENT',
    name: language.label.rent,
  },
  {
    id: 'SHOP',
    name: language.label.shop,
  },
];

export const APARTMENT_TYPE_BY_ID = convertArrToObj(APARTMENT_TYPE_OPTIONS, 'id');

export const APARTMENT_STATUS_EMPTY = 'EMPTY';
export const APARTMENT_STATUS_IN_USE = 'IN_USE';
export const APARTMENT_STATUS_OPTIONS = [
  {
    id: APARTMENT_STATUS_EMPTY,
    name: language.label.empty,
  },
  {
    id: APARTMENT_STATUS_IN_USE,
    name: language.label.in_use,
  },
];

export const DEFAULT_APARTMENT_FILTER = {
  floorId: '',
  apartmentType: '',
  status: '',
  name: '',
  customerCode: '',
  owner: '',
  buildStatus: '',
  dateOfTakingApartment: [],
};
