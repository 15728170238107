import isNil from 'lodash/isNil';
import threeDigits from '../threeDigits';

export default function calculateSurchargeTax(taxPercent = 0, totalSurcharge) {
  const totalVatSurcharge = Math.round(!isNil(taxPercent) ? (taxPercent * totalSurcharge) / 100 : 0);
  return {
    vatSurcharge: Number(totalVatSurcharge),
    vatSurchargeFormat: threeDigits(totalVatSurcharge) ?? '',
  };
}
