const iconPdf = `/assets/images/icon-pdf.png`;
const iconDocx = `/assets/images/icon-docx.jpg`;
const iconXlsx = `/assets/images/icon-xlsx.jpg`;

export const FILE_ICON = {
  xlsx: iconXlsx,
  xls: iconXlsx,
  docx: iconDocx,
  doc: iconDocx,
  pdf: iconPdf,
};

export const EXCEL_EXT = ['.xls', '.xlsx'];
export const IMAGE_EXT = ['.jpg', '.jpeg', '.png', '.gif'];
export const DOCS_EXT = ['.doc', '.docx', '.pdf'];
export const VIDEO_EXT = ['.mp4', '.mov'];
