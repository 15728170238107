import * as utils from 'shared/utils';
import { language } from '../utils/language';

export const LABEL_ALL = 'ALL';
export const LABEL_CUSTOMER_CODE = 'CUSTOMER_CODE';
export const LABEL_ACCOUNTING = 'ACCOUNTING';
export const LABEL_INTERNAL = 'INTERNAL';
export const LABEL_HELP_DESK = 'HELP_DESK';
export const LABEL_BUSINESS_CONTRACT = 'BUSINESS_CONTRACT';

export const LABEL_TYPE_OPTIONS = [
  { id: LABEL_ALL, name: language.general },
  { id: LABEL_ACCOUNTING, name: language.accounting },
  { id: LABEL_CUSTOMER_CODE, name: language.customer_code },
  { id: LABEL_INTERNAL, name: language.internal },
  { id: LABEL_HELP_DESK, name: language.help_desk },
  { id: LABEL_BUSINESS_CONTRACT, name: language.contract },
];

export const LABEL_TYPE_OBJ = utils.convertArrToObj(LABEL_TYPE_OPTIONS, 'id');
