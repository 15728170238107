import notification from 'components/Notification';
import { language } from '../utils/language';

export default function printHtml(html) {
  const mywindow = window.open('Print', '', '', '');
  if (mywindow) {
    mywindow.document.write(html);
    mywindow.document.close();
    mywindow.onload = () => {
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    };
  } else {
    notification.error(language.error_description.print_error);
  }
}
